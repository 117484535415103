import { FC } from 'react';
import { Col, Image, Row, Stack } from 'react-bootstrap';
import iot from './IoT.jpg';
import android from './android.jpg';
import cloud from './cloud.jpg';
import digitalization from './digitalization.jpg';

interface ServicesProps { }

const imageHeight: number = 600;

const items = [
  {
    title: "INTERNET OF THINGS",
    image: iot,
    content: "Wie vernetze ich Geräte unterschiedlichster Art? Wie verarbeite ich Millionen Datenpunkte in Echtzeit? Wie skaliere ich mein System? Wie mache ich mein System fehlertolerant? Ich helfe Ihnen dabei, diese entscheidenden Fragen zu beantworten und die richtige Lösung für Ihre IoT-Anwendung zu finden. Mit fundiertem Know-how und maßgeschneiderten Lösungen stehe ich Ihnen zur Seite, um Ihre Herausforderungen im Internet der Dinge zu bewältigen."
  },
  {
    title: "APP-ENTWICKLUNG",
    image: android,
    content: "Wie entwickle ich effizient für die Android-Plattform? Wie optimiere ich meine App für eine reibungslose Performance? Wie implementiere ich innovative Features und nutzerfreundliche Schnittstellen? Wie gewährleiste ich die Sicherheit meiner Android-Anwendung? Ich unterstütze Sie dabei, die richtigen Antworten auf diese Fragen zu finden und maßgeschneiderte Lösungen für Ihre Android-App zu realisieren. Durch mein umfassendes Know-how und zielgerichtete Entwicklungsstrategien stehe ich Ihnen zur Seite, um Ihre Anforderungen im Android-Bereich erfolgreich umzusetzen."
  },
  {
    title: "DIGITALISIERUNG",
    image: digitalization,
    content: "Wie digitalisiere ich meine Geschäftsprozesse? Welche Technologien wähle ich aus? Wo hoste ich meine Software-Services? Wie setze ich meine Anforderungen am besten um? Ich stehe Ihnen mit fundiertem Know-how und maßgeschneiderten Lösungen zur Seite, um diese Fragen zu beantworten und die richtige Lösung für Ihre Geschäftsprozesse zu finden. Gemeinsam werden wir Ihre Herausforderungen bewältigen."
  },
  {
    title: "CLOUD",
    image: cloud,
    content: "Wie bringe ich mein System in die Cloud? Wie stelle ich meine Infrastruktur automatisiert bereit? Wie sichere ich meine Daten gegen Angriffe ab?  Ich biete Ihnen umfassendes Fachwissen und individuell angepasste Lösungen, um diese Fragen zu klären und die optimale Cloud-Integration für Ihre Geschäftsprozesse zu realisieren. Gemeinsam werden wir die Herausforderungen meistern und Ihre Unternehmensabläufe erfolgreich in die Cloud überführen."
  }
];

const Services: FC<ServicesProps> = () => (
  <Stack gap={2}>

    {items.map((section, index) => (
      <Row className="d-none d-md-flex" key={index}>
        <Col md={6} className={index % 2 === 0 ? "" : "order-last"}>
          <Image src={section.image} height={imageHeight} fluid />
        </Col>
        <Col md={6}>
          <Row>
            <Col>
              <h1>{section.title}</h1>
              <p>{section.content}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    ))
    }
    {items.map((section, index) => (
      <Row className="d-md-none" key={index}>
        <Col>
          <Image src={section.image} height={imageHeight} fluid className='mb-2'/>
          <h1>{section.title}</h1>
          <p>{section.content}</p>
        </Col>
      </Row>
    ))
    }
  </Stack>
);

export default Services;
