import { FC } from 'react';
import { Card, Stack } from 'react-bootstrap';
import { FaBalanceScaleRight, FaHandsHelping } from "react-icons/fa";
import { GrTestDesktop } from "react-icons/gr";
import { MdSpeed } from "react-icons/md";
import "./About.scss";

interface AboutProps { }

const iconStyle = {
  width: '32px',
  height: '32px'
};

const About: FC<AboutProps> = () => (
  <Stack gap={3}>
    <div className='d-flex justify-content-center'>
      <Card className='border-0 m-4' style={{ width: "800px" }}>
        <Card.Body>
          <figure className="text-center">
            <blockquote className="blockquote quoteText">
              <p className="quoteText">“I will, in fact, claim
                that the difference between a bad programmer and a good one is
                whether he considers his code or his data <span className='text-primary'>structure</span> more important.
                Bad programmers worry about the code.
                Good programmers worry about data structure and their <span className='text-primary'>relationships</span>.”</p>
            </blockquote>
            <figcaption className="blockquote-footer">
              Linus Torvalds
            </figcaption>
          </figure>
        </Card.Body>
      </Card>
    </div>
    <h1>Über mich</h1>

    <div >
      <p>Hallo und herzlich willkommen auf meiner Homepage. Mein Name ist Niklas Cramer, ich bin Softwareentwickler aus Leidenschaft.
        Mein erstes Programm schrieb ich in der Programmiersprache C, seither ließ mich die Faszination für Software nicht mehr los.
        Während eines Praktikums im Rahmen meines Studiums begann ich mit der objektorientierten Programmierung, meine erste Anwendung war ein Tool, welches Daten von akustischen Messgeräten automatisch auswertet. Außerdem entwickelte ich ein System weiter, welches verteilte akustische Messstationen live auf dem iPad betrachten lässt.
        Dies war die Zeit, in der mir klar wurde, dass ich hauptberuflich Softwareentwickler werden möchte.
      </p>
      <p>
        Nach meinem Studium arbeitete ich bei dem Automobilzulieferer XTRONIC GmbH. Als Systemingenieur.
        Im Rahmen dieser Tätigkeit entwickelte ich einige Android-Apps im IoT-Bereich, sowie einige Anwendungen im AR und VR-Bereich.
        Hierbei konnte ich stets auf mein Hintergrundwissen aus dem privaten Bereich, welches ich mir über Jahre aufgebaut habe, zurückgreifen, da ich mich in meiner Freizeit mit 3D-Modelling und Rendering beschäftigt habe.
      </p>
      <p>
        Nach meiner Tätigkeit bei der XTRONIC GmbH. wechselte ich zur Zühlke GmbH. Zühlke ist ein Dienstleister im Software Bereich, der eine hohe Reputation genießt, da viel Wert auf Qualität gelegt wird.
        Während meiner Zeit bei Zühlke entwickelte ich Client-Applikationen in WPF und Android, sowie Backend-Applikationen im .NET ecosystem On-Premises und in der Azure-Cloud.
      </p>
      <p>
        In meiner Freizeit beschäftige ich mich mit neuen Technologien für Android, .NET und mit Architekturmustern für skalierbare Cloud-Anwendungen, sowie den best practices der Entwicklung von Software-Systemen.
      </p>
    </div>
    <hr></hr>

    <h1 >Meine Prinzipien</h1>

    <div className="d-grid gap-4">
      <div >
        <div className='d-flex justify-content-between align-items-center'>
          <h2 >Pragmatismus</h2>
          <FaBalanceScaleRight className='m-2' style={iconStyle} />
        </div>
        <p>
          Pragmatismus in der Softwareentwicklung bedeutet, dass die Software die definierten Anforderungen erfüllen muss, um den größtmöglichen Nutzen für den jeweiligen Zweck zu gewährleisten, ohne sich unnötig in theoretischen Überlegungen oder übermäßiger Komplexität zu verlieren. Es geht darum, effektive Lösungen zu finden, die die gestellten Probleme adäquat lösen, dabei jedoch auf eine praktische und effiziente Weise, ohne unnötigen Ballast. Denn in der Regel muss kein Framework, sondern eine Lösung für ein konkretes Problem entwickelt werden.
        </p>
      </div>

      <div >
        <div className='d-flex justify-content-between align-items-center'>
          <h2>Qualität</h2>
          <GrTestDesktop className='m-2' style={iconStyle} />
        </div>
        <p>
          Qualität in der Softwareentwicklung bedeutet, dass die Software durch umfassende Tests auf verschiedenen Ebenen abgesichert sein muss. Dies schließt nicht nur die regelmäßige Überprüfung von einzelnen Codezeilen durch Unit-Tests ein, sondern umfasst auch Integrationstests, Systemtests und gegebenenfalls Akzeptanztests. Durch diese vielschichtige Teststrategie wird sichergestellt, dass die Software nicht nur auf Einzelebene funktioniert, sondern auch in ihrer Gesamtheit reibungslos operiert und den gewünschten Anforderungen entspricht. Qualitätsprüfungen sollten nicht nur auf funktionale Aspekte beschränkt sein, sondern auch Performance, Sicherheit und Benutzerfreundlichkeit umfassen, um eine umfassende Softwarequalität zu gewährleisten.
        </p>
      </div>

      <div >
        <div className='d-flex justify-content-between align-items-center'>
          <h2>Teamwork</h2>
          <FaHandsHelping className='m-2' style={iconStyle} />
        </div>
        <p>
          Teamwork in der Softwareentwicklung erfordert einen Rahmen des gegenseitigen Respekts und konstruktiven Feedbacks, um optimale Ergebnisse zu erzielen. Ein respektvolles Arbeitsumfeld fördert eine offene Kommunikation und schafft Raum für verschiedene Perspektiven, Fähigkeiten und Ideen. Teammitglieder sollten die Vielfalt ihrer Kollegen schätzen und gemeinsam daran arbeiten, ein inklusives Umfeld zu schaffen, in dem jeder seine Stärken einbringen kann.
        </p>
      </div>
      <div >
        <div className='d-flex justify-content-between align-items-center'>
          <h2>Höchstleistung</h2>
          <MdSpeed className='m-2' style={iconStyle} />
        </div>
        <p>
          In Bezug auf die Leistung ist es entscheidend, dass jedes Teammitglied sich verpflichtet fühlt, stets sein Bestes zu geben. Das Streben nach Exzellenz und kontinuierliche Verbesserung sind Schlüsselelemente für den Erfolg eines Software-Entwicklungsteams. Durch die Hingabe zu höchster Leistungsbereitschaft trägt jedes Teammitglied dazu bei, die gesteckten Ziele zu erreichen und den Gesamterfolg des Projekts sicherzustellen. Diese Verpflichtung geht über individuelle Fähigkeiten hinaus und umfasst die Bereitschaft, sich neuen Herausforderungen zu stellen, Wissen zu erweitern und proaktiv zur Entwicklung innovativer Lösungen beizutragen. In einem Umfeld, in dem jedes Teammitglied sein Bestes gibt, entsteht eine kraftvolle Synergie, die nicht nur die individuelle Leistung, sondern auch die Gesamtleistung des Teams auf ein höheres Niveau hebt.
        </p>
      </div>
    </div>
  </Stack>
);

export default About;
