import { useState } from 'react';
import { Button, Container, Row } from 'react-bootstrap';
import { IoMdMail } from "react-icons/io";
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from './ThemeContext';
import About from './components/About/About';
import ContactForm from './components/Contact/ContactForm';
import Impressum from './components/Impressum/Impressum';
import NavigationBar from './components/NavigationBar/NavigationBar';
import Projects from './components/Projects/Projects';
import Services from './components/Services/Services';
import Footer from './components/Footer/Footer';
import NotFound from './components/NotFound/NotFound';

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<About />} />
            <Route path="about" element={<About />} />
            <Route path="projects" element={<Projects />} />
            <Route path="services" element={<Services />} />
            <Route path="impressum" element={<Impressum />} />
            {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;

function Layout() {

  const [showContactForm, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      
      <NavigationBar />

      <div className='container-fluid pb-5 '>

        <Container className="main-container mt-4">
          <Outlet />
        </Container>

        <Row className="sticky-bottom justify-content-end">
          <Button className='contact-button m-4' variant="primary" onClick={openModal}>
            <IoMdMail className='contact-icon' />
          </Button >
        </Row>

      </div>
      <ContactForm show={showContactForm} onClose={closeModal} />
      <Footer />
    </div >
  );
}