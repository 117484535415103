import HCaptcha from '@hcaptcha/react-hcaptcha';
import axios from 'axios';
import * as formik from 'formik';
import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import * as yup from 'yup';
import './ContactForm.scss';

interface ContactFormProps {
  show: boolean;
  onClose: () => void;
}

export interface FormData {
  name: string;
  email: string;
  phone: string;
  message: string;
}

const ContactForm: React.FC<ContactFormProps> = ({ onClose, show }) => {
  const { Formik } = formik;

  const [captcha, setCaptcha] = useState("");

  const onHCaptchaChange = (token: string) => {
    setCaptcha(token);
  };

  const handleSubmit = async (values: {
    name: string | undefined;
    email: string;
    phone: string | undefined;
    message: string;
  }) => {
    await axios.post(
      'https://api.web3forms.com/submit',
      {
        ...values,
        subject: "Kontaktaufnahme via Website",
        access_key: "6140f304-6cb0-4cd7-9c45-1920f981e1ca",
        replyto: values.email,
        from_name: values.name
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
      }
    );
    alert("Ihre Nachricht wurde gesendet.");
  };

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const schema = yup.object().shape({
    email: yup.string().email().required(),
    message: yup.string().required().min(1),
    phone: yup.string().matches(phoneRegExp, 'Phone number is not valid')
  });

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Kontakt</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          initialValues={{
            name: '',
            email: '',
            phone: '',
            message: ''
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit} >
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name='name'
                  value={values.name}
                  onChange={handleChange}
                  isInvalid={!!errors.name}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="email">
                <Form.Label>E-Mail Addresse*</Form.Label>
                <Form.Control
                  required
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="tel">
                <Form.Label>Telefonnummer</Form.Label>
                <Form.Control
                  type="phone"
                  name="phone"
                  value={values.phone}
                  isInvalid={!!errors.phone}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3 has-validation" controlId="text">
                <Form.Label>Nachricht*</Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  rows={6}
                  name="message"
                  value={values.message}
                  isInvalid={!!errors.message}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.message}
                </Form.Control.Feedback>
              </Form.Group>
              <HCaptcha
                sitekey="50b2fe65-b00b-4b9e-ad62-3ba471098be2"
                onVerify={onHCaptchaChange}
                theme='dark'
              />
              <Button
                disabled={captcha === ''}
                type="submit"
                onClick={onClose}>
                Abschicken
              </Button>
            </Form>)}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ContactForm;