import React, { FC } from 'react';
import { Stack } from 'react-bootstrap';
import "./Projects.scss";
import projectsList from "./projects-list.json";

interface ProjectsProps { }


const Projects: FC<ProjectsProps> = () => {
   return (
      <Stack gap={2}>
         {projectsList.map((project, index) => (
            <Project
               key={index}
               name={project.name}
               date={project.date}
               tasks={project.tasks}
            />
         ))}
      </Stack>
   );
};

export default Projects;

interface ProjectProps {
   name: string,
   date: string,
   tasks: string[],
}

class Project extends React.Component<ProjectProps> {
   render() {
      return (
         <div className='card my-4 p-4'>
            <h2>{this.props.name}</h2>
            <p className="mb-2 text-muted font-weight-light">{this.props.date}</p>
            <ul>
               <h5>Tätigkeiten</h5>
               <ul>
                  {this.props.tasks.map((task, index) => (
                     <li key={index}>{task}</li>
                  ))}
               </ul>
            </ul>
         </div>
      );
   }
}