// ThemeContext.tsx
import React, {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react';

interface ThemeContextProps {
    darkMode: boolean;
    toggleTheme: () => void;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

interface ThemeProviderProps {
    children: ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
    const [darkMode, setDarkMode] = useState(() => {
        const savedMode = localStorage.getItem('darkMode');
        return savedMode ? JSON.parse(savedMode) : false;
    });

    const toggleTheme = () => {
        setDarkMode((prevMode: boolean) => !prevMode);
    };

    useEffect(() => {
        localStorage.setItem('darkMode', JSON.stringify(darkMode));
    }, [darkMode]);

    const contextValue: ThemeContextProps = { darkMode, toggleTheme };

    return (
        <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>
    );
};



export const useTheme = (): ThemeContextProps => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
};
