import {FC} from 'react';
import {Col, Row} from 'react-bootstrap';
import {FaGithub, FaLinkedin, FaXing} from 'react-icons/fa';
import './Footer.module.scss';
import {Link} from "react-router-dom";

interface FooterProps {
}

const iconStyle = {
    width: '32px',
    height: '32px'
};

const Footer: FC<FooterProps> = () => {
    const currentYear = new Date().getFullYear();
    return (
        <footer className='footer'>
            <Row className='m-auto my-1'>
                <Col className='justify-content-start mt-auto'>
                    <div className="p-2">&copy; {currentYear} Niklas Cramer</div>
                </Col>
                <Col className='justify-content-center m-auto'>
                    <Row className='justify-content-center m-auto'>
                        <Col className='col-auto'>
                            <a href="https://www.xing.com/profile/Niklas_Cramer" className='footer-link-icon'>
                                <FaXing style={iconStyle}/>
                            </a>
                        </Col>
                        <Col className='col-auto'>
                            <a href="https://www.linkedin.com/in/niklas-cramer-a0557b208/" className='footer-link-icon'>
                                <FaLinkedin style={iconStyle}/>
                            </a>
                        </Col>
                        <Col className='col-auto'>
                            <a href="https://github.com/CodingBlob" className='footer-link-icon'>
                                <FaGithub style={iconStyle}/>
                            </a>
                        </Col>
                    </Row>
                </Col>
                <Col className='justify-content-end d-flex p-2 mt-auto'>
                    <Link to="/impressum">Impressum</Link>
                </Col>
            </Row>
        </footer>
    );
};

export default Footer;
