import './NotFound.scss';

const NotFound = () => (
  <div className="row justify-content-md-center  text-center">
    <div className="not-found">
      404
    </div>
    <p className='mx-auto under'>-NOT FOUND-</p>
  </div>
);

export default NotFound;
