import * as tween from '@tweenjs/tween.js';
import interpolate from 'color-interpolate';
import { CSSProperties, useEffect, useRef } from 'react';
import { Button, Nav, Navbar } from 'react-bootstrap';
import { FaDownload } from "react-icons/fa";
import { Link } from 'react-router-dom';
import * as three from 'three';
import portrait from '../../images/portrait-small.png'; // Tell webpack this JS file uses this image
import './NavigationBar.scss';
import pdf from "./Profil Niklas Cramer.pdf";

const NavigationBar = () => {

   const onClick = () => {
      window.open(pdf);
   };

   return (
      <div className="navbar-wrapper">
         <Navbar expand="md" collapseOnSelect>
            <Navbar.Toggle className='border-0'>
               <span className="icon-bar" />
               <span className="icon-bar" />
               <span className="icon-bar" />
            </Navbar.Toggle>
            <Navbar.Brand className='m-2' as={Link} to="/">
               <div className="circular-image-container back-shadow">
                  <img alt='portrait' src={portrait} className="circular-image" />
               </div>
            </Navbar.Brand>
            <Navbar.Collapse className='justify-content-between' >
               <Nav className="mr-auto">
                  <Nav.Link eventKey="1" as={Link} to="/services">
                     Dienstleistungen
                  </Nav.Link>
                  <Nav.Link eventKey="2" as={Link} to="/projects">
                     Projekte
                  </Nav.Link>
               </Nav>
               <Nav className='ml-auto mr-0'>

                  <Button onClick={onClick} className='btn-primary m-2 d-inline-flex flex-row align-items-center'>
                     <FaDownload className='ml-2' />
                     <div className='mx-2'>Profil</div>
                  </Button>
               </Nav>
            </Navbar.Collapse>
         </Navbar >
         <NavbarBackground style={{ zIndex: -1 }}></NavbarBackground>
      </div>
   );
};

export default NavigationBar;

interface NavbarBackgroundProps {
   style?: CSSProperties;
}

const NavbarBackground = (props: NavbarBackgroundProps) => {
   const canvasRef = useRef<HTMLCanvasElement | null>(null);

   useEffect(() => {

      const handleResize: ResizeObserverCallback = (entries) => {
         hexAnimated.dispose();
         hexAnimated = new NavbarHexagons(canvas);
      };

      const resizeObserver = new ResizeObserver(handleResize);

      if (canvasRef.current) {
         resizeObserver.observe(canvasRef.current);
      }

      const canvas = canvasRef.current!;
      let hexAnimated = new NavbarHexagons(canvas);

      // Clean up the observer when the component is unmounted
      return () => {
         hexAnimated.dispose();
         resizeObserver.disconnect();
      };
   }, []); // Run this effect only once on mount

   return <canvas ref={canvasRef} className="navbar-canvas" style={props.style}></canvas>;
};

const rows = 6;

export class NavbarHexagons {
   hexagons = new Array<NavbarHexagon>()
   isDisposed: boolean = false;
   context: CanvasRenderingContext2D;
   canvas: HTMLCanvasElement;
   animatedHexagons = 0;

   constructor(canvas: HTMLCanvasElement) {
      this.canvas = canvas;
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      this.context = canvas.getContext("2d")!;

      const radius = canvas.height / rows;
      const columns = Math.round(canvas.width * 0.6 / radius);
      const padding = 2;
      const target = new three.Vector2(0, 0);

      for (let i = 0; i < columns; i++) {
         for (let j = 0; j < rows + 1; j += 2) {

            const evenX = (i % 2 === 0);
            const evenY = (j % 8 === 0);
            const centerX = (i) * radius * padding * 0.8660254;
            const centerY = (j + (evenX ? 1 : 0)) * radius * padding * 0.5;
            const center = new three.Vector2(centerX, centerY);
            const hex = new NavbarHexagon(
               this.context,
               center,
               radius,
               i * 1.0 / columns
            );
            let offsetY = canvas.height * 1.0;
            if (evenY)
               offsetY *= -1;
            hex.offset = new three.Vector2(0, offsetY);
            new tween.Tween(hex.offset)
               .to(target, 500)
               .delay((Math.abs(columns / 2 - i) + j) * 20)
               .onUpdate(x => hex.offset.copy(x))
               .easing(tween.Easing.Cubic.InOut)
               .onComplete(x => {
                  if (--this.animatedHexagons === 0)
                     this.dispose();
               })
               .start();
            this.hexagons.push(hex);

            this.animatedHexagons++;
         }
      }

      requestAnimationFrame(() => this.render());
   }
   render(): void {
      if (this.isDisposed)
         return;
      tween.update();
      this.context.fillStyle = "rgba(21,22,24,1)";
      this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
      this.hexagons.forEach(x => x.render());
      requestAnimationFrame(() => this.render());
   }

   dispose() {
      this.isDisposed = true;
   }
}

const colorStart = "rgba(46, 54, 56, 0.5)";
const colorEnd = "rgba(46, 54, 56, 0.9)";
const strokeColorMap = interpolate([colorEnd, colorStart, colorEnd]);

const fillStart = "rgba(34, 40, 42, 0.4)";
const fillEnd = "rgba(34, 40, 42, 0.2)";
const fillColorMap = interpolate([fillEnd, fillStart, fillEnd]);

export class NavbarHexagon {
   center: three.Vector2;
   context: CanvasRenderingContext2D;
   offset = new three.Vector2(0, 0);
   vertices = new Array<three.Vector2>();
   column: number;
   strokeColor: string;
   fillColor: string;

   constructor(
      renderContext: CanvasRenderingContext2D,
      center: three.Vector2,
      radius: number,
      column: number) {
      this.center = center;
      this.context = renderContext;
      this.column = column;
      const vertCount = 6;
      for (let i = 0; i < vertCount; i++) {
         let x = radius * Math.cos(2.0 * Math.PI * i / vertCount);
         let y = radius * Math.sin(2.0 * Math.PI * i / vertCount);
         this.vertices[i] = new three.Vector2(x, y).add(center);
      }
      this.strokeColor = strokeColorMap(column);
      this.fillColor = fillColorMap(column);
   }

   render() {
      this.context.fillStyle = this.fillColor;
      this.context.strokeStyle = this.strokeColor;
      this.context.lineWidth = Math.sin(this.column * Math.PI * 1.0) * 0.6;
      this.context.beginPath();
      this.context.moveTo(this.vertices[0].x + this.offset.x, this.vertices[0].y + this.offset.y);
      for (let i = 1; i < this.vertices.length; i++) {
         const vertex = this.vertices[i];
         this.context.lineTo(vertex.x + this.offset.x, vertex.y + this.offset.y);
      }
      this.context.closePath()
      this.context.fill();
      this.context.stroke();
   }
}